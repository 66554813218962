<template>
  <div>
    <el-card shadow="never" class="card-custom-all">
      <el-row :gutter="30">
        <el-col :span="24">
          <el-row :gutter="30">
            <el-col :span="24" :md="12">
              <el-form-item label="ชื่อกิจกรรม/โครงการ" prop="projectName">
                <el-input
                  id="formDataProjectName"
                  v-model="formData.projectName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item
                label="วันที่รับสมัคร"
                prop="dateRegister"
                class="is-required"
              >
                <el-date-picker
                  id="formDataDate"
                  class="custom-date-picker"
                  v-model="date"
                  type="daterange"
                  start-placeholder="วันที่รับสมัคร"
                  range-separator="ถึง"
                  end-placeholder="วันที่ปิดรับสมัคร"
                  @change="changeDate"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24">
          <el-row :gutter="30">
            <el-col :span="24" :md="12">
              <el-form-item
                label="วันที่จัดกิจกรรม/โครงการ"
                prop="eventDate"
                class="mg-0"
              >
                <el-date-picker
                  id="formDataEventDate"
                  v-model="dateEvent"
                  type="daterange"
                  start-placeholder="วันที่จัดกิจกรรม"
                  range-separator="ถึง"
                  end-placeholder="วันที่ปิดจัดกิจกรรม"
                  @change="changeDate('event')"
                  placeholder=""
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item label="สถานที่จัดกิจกรรม/โครงการ" prop="eventLocation">
                <el-input
                  id="formDataEventLocation"
                  v-model="formData.eventLocation"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="24">
          <el-row :gutter="30">
            <el-col :span="24" :md="12">
              <el-form-item label="เจ้าของกิจกรรม/โครงการ" prop="ownerName">
                <el-input
                  id="formDataOwnerName"
                  v-model="formData.ownerName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item label="รูปภาพ">
                <div class="box-addImg">
                  <el-upload
                    action="#"
                    :auto-upload="false"
                    :on-change="
                      (file, fileList) => uploadDocuments(file, fileList)
                    "
                    :on-remove="handleRemoveDocuments"
                    accept="image/jpeg,image,image/png"
                    :fileList="fileList"
                    list-type="picture"
                    :limit="1"
                    :on-exceed="handleExceed"
                  >
                    <el-button type="primary" class="font-16"
                      >แนบไฟล์</el-button
                    >
                    <p
                      class="text-left mg-0 text-grey"
                      style="margin-top: -8px !important"
                    >
                      Max file size: 25MB
                    </p>
                  </el-upload>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col :span="24" :md="12">
          <el-row :gutter="10">
            <el-form-item label="รูปภาพ">
              <el-col :span="24" class="box-addImg">
                <el-upload
                  action="#"
                  :auto-upload="false"
                  :on-change="
                    (file, fileList) => uploadDocuments(file, fileList)
                  "
                  :on-remove="handleRemoveDocuments"
                  accept="image/jpeg,image,image/png"
                  :fileList="fileList"
                  multiple
                  list-type="picture"
                >
                  <el-button type="primary" class="font-16">แนบไฟล์</el-button>
                </el-upload>
              </el-col>
            </el-form-item>
          </el-row>
        </el-col> -->

        <el-col :span="24">
          <el-form-item label="รายละเอียดกิจกรรม/โครงการ" class="custom-editor">
            <QuillTextEditor
              :data="formData"
              :name="`projectDescription`"
              :pageName="`project`"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <div class="is-flex js-end">
        <router-link :to="`/manage/project`">
          <el-button class="font-16 btn-cancle"> ยกเลิก</el-button>
        </router-link>
        <el-button
          type="primary"
          class="font-16"
          @click="checkIfProject"
          :loading="loadingSave"
          >บันทึก</el-button
        >
      </div>
    </el-card>
  </div>
</template>
<script>
//quill editor
import QuillTextEditor from "@/components/editor/QuillTextEditor";

export default {
  props: ["formData", "loadingSave"],
  components: { QuillTextEditor },
  watch: {
    formData() {
      this.setData();
    },
  },
  data() {
    return {
      date: [],
      dateEvent: [],
      imgUrlTERAK: process.env.VUE_APP_BASE_URL_TERAK,
      fileList: [],
    };
  },
  mounted() {
    this.setData() 
  },
  methods: {
    setData() {
      let obj = [this.formData.registerStart, this.formData.registerEnd];
      this.dateEvent = [
        this.formData.eventDate,
        this.formData.eventDateEnd,
      ];
      this.date = obj;
      if (this.formData.image != "") {
        this.fileList.push({
          name: this.formData.image,
          url: this.imgUrlTERAK + "get/file/cfoe/" + this.formData.image,
        });
      }
      // for (let index = 0; index < this.formData.file.length; index++) {
      //   let obj1 = {
      //     name: this.formData.file[index].fileName,
      //     url: this.imgUrl + this.formData.file[index].fileName,
      //   };
      //   this.fileList.push(obj1);
      // }
    },
    changeDate(type) {
      if (type == "event") {
        this.formData.eventDate = this.dateEvent[0];
        this.formData.eventDateEnd = this.dateEvent[1];
      } else {
        this.formData.registerStart = this.date[0];
        this.formData.registerEnd = this.date[1];
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(`คุณสามารถแนบไฟล์ได้สูงสุด 1 ไฟล์`);
    },
    uploadDocuments(file, fileList) {
      let typeThis = file.raw.name.split(".");

      if (
        typeThis[1] == "jpeg" ||
        typeThis[1] == "png" ||
        typeThis[1] == "jpg"
      ) {
        if (file.size < 25000000) {
          let obj = {
            name: file.name,
            file: file,
          };
          this.formData.file.push(obj);
        } else {
          let index = fileList.findIndex((a) => a.uid == file.uid);
          fileList.splice(index, 1);
          this.$message({
            message: "กรุณาเลือกไฟล์ขนาดไม่เกิน 25mb",
            type: "error",
            duration: 4000,
          });
        }
      } else {
        let index = fileList.findIndex((a) => a.uid == file.uid);
        fileList.splice(index, 1);
        this.$message({
          message: "กรุณาเลือกไฟล์นามสกุลเป็น JPEG หรือ PNG เท่านั้น",
          type: "error",
          duration: 4000,
        });
      }
    },
    handleRemoveDocuments(file) {
      if (typeof file.raw == "undefined") {
        this.formData.delImg = file.name;
      }
      this.formData.image = "";
      this.formData.file.splice(0, 1);
      // let index = this.formData.file.findIndex((f) =>
      //   typeof f._id == "undefined"
      //     ? f.file.uid == file.uid
      //     : f.fileName == file.name
      // );
      // let indexImgOld = this.formData.file.find((f) =>
      //   typeof f._id != "undefined" ? f.fileName == file.name : ""
      // );
      // this.formData.file.splice(index, 1);
      // if (typeof indexImgOld != "undefined") {
      //   if (typeof indexImgOld != "undefined") {
      //     this.formData.file = [];
      //     this.formData.delImg = this.formData.image;
      //     this.formData.image = "";
      //   }
      // }
    },
    checkIfProject() {
      // if (
      //   this.formData.isGrade == false &&
      //   this.formData.isIncome == false &&
      //   this.formData.isRateReady == false &&
      //   this.formData.isFactorRateReady == false
      // ) {
      //   this.$message({
      //     message: "กรุณาเลือกเงื่อนไขโครงการ",
      //     type: "error",
      //     duration: 4000,
      //   });
      // } else {
        this.$emit("projectEdit", this.formData);
      // }
    },
  },
};
</script>