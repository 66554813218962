<template>
  <DefaultTemplate
    v-loading.lock="loadingData"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="box-name-page">
      <div>
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="set-breadcrumb"
        >
          <el-breadcrumb-item :to="{ path: '/manage/project' }"
            >จัดการข้อมูลโครงการ</el-breadcrumb-item
          >
          <el-breadcrumb-item>แก้ไขข้อมูลโครงการ</el-breadcrumb-item>
        </el-breadcrumb>
        <p class="title mg-t-4">แก้ไขข้อมูลโครงการ</p>
      </div>
    </div>
    <div v-loading="loading && !loadingData" v-if="!loading">
      <el-form
        label-position="top"
        :model="form"
        class="set-form"
        :rules="rules"
        ref="form"
      >
        <Step1 @stepNow="formDataOut" :formData="form" />
        <Step2
          @projectEdit="projectEdit"
          :formData="form"
          :loadingSave="loadingSave"
        />
      </el-form>
    </div>
  </DefaultTemplate>
</template>

<script>
import numeral from "numeral";
import DefaultTemplate from "@/template/DefaultTemplate";
import Step1 from "@/components/project/edit/Step1";
import Step2 from "@/components/project/edit/Step2";
import { HTTP } from "@/service/axios";
import { TERAK } from "@/service/axios";
import jwt from "jsonwebtoken";

export default {
  components: {
    DefaultTemplate,
    Step1,
    Step2,
  },
  data() {
    var validGrade = (rule, value, callback) => {
      if (this.form.grade.length == 0) {
        callback(new Error("กรุณาเลือกเกรด"));
      } else {
        callback();
      }
    };
    var validIncomeMax = (rule, value, callback) => {
      if (this.form.incomeMax === "") {
        callback(new Error("กรุณากรอกตัวเลขรายได้"));
      } else {
        if (
          parseInt(this.form.incomeMax) != 0 &&
          parseInt(this.form.incomeMin) >= 0
        ) {
          if (parseInt(this.form.incomeMax) < parseInt(this.form.incomeMin)) {
            callback(new Error("รายได้สิ้นสุดน้อยกว่ารายได้เริ่มต้น"));
          } else if (this.form.incomeMax > 10000000000000) {
            callback(new Error("กรุณากรอกตัวเลขไม่เกิน 9 ล้านล้าน"));
          } else {
            callback();
          }
        } else {
          callback(new Error("รายได้สิ้นสุดต้องมากกว่า 0"));
        }
      }
    };
    var validIncomeMin = (rule, value, callback) => {
      if (this.form.incomeMin === "") {
        callback(new Error("กรุณากรอกตัวเลขรายได้"));
      } else {
        if (parseInt(this.form.incomeMin) > parseInt(this.form.incomeMax)) {
          callback(new Error("รายได้เริ่มต้นมากกว่ารายได้สิ้นสุด"));
        } else if (this.form.incomeMin > 90000000000) {
          callback(new Error("กรุณากรอกตัวเลขไม่เกิน 9 หมื่นล้าน"));
        } else {
          callback();
        }
      }
    };
    var validRegister = (rule, value, callback) => {
      if (this.form.registerStart == "" && this.form.registerEnd == "") {
        callback(new Error("กรุณาเลือกวันที่รับสมัคร"));
      } else {
        callback();
      }
    };

    return {
      loadingData: true,
      loadingSave: false,
      loading: true,
      idData: this.$route.params.id,
      form: {},
      rules: {
        gradeValid: [{ validator: validGrade, trigger: ["blur", "change"] }],
        incomeMinValid: [{ validator: validIncomeMin, trigger: "blur" }],
        incomeMaxValid: [{ validator: validIncomeMax, trigger: "blur" }],
        eventLocation: [
          {
            required: true,
            message: "กรุณากรอกสถานที่จัดกิจกรรม",
            trigger: "blur",
          },
        ],
        registerType: [
          {
            required: true,
            message: "กรุณาเลือกประเภทของการสมัครโครงการ",
            trigger: "change",
          },
        ],
        registerUrl: [
          {
            required: true,
            message: "กรุณากรอกแนบลิงก์สมัครโครงการ",
            trigger: "blur",
          },
        ],
        ownerName: [
          {
            required: true,
            message: "กรุณากรอกเจ้าของโครงการ",
            trigger: "blur",
          },
        ],
        projectName: [
          {
            required: true,
            message: "กรุณากรอกชื่อกิจกรรม/โครงการ",
            trigger: "blur",
          },
        ],
        eventDate: [
          {
            required: true,
            message: "กรุณาเลือกวันที่จัดกิจกรรม",
            trigger: "change",
          },
        ],
        dateRegister: [{ validator: validRegister, trigger: "blur" }],
        projectTeraks: [
          {
            required: true,
            message: "กรุณาเลือกโครงการจากระบบ TERAK",
            trigger: "change",
          },
        ],
        conditionProject: [
          {
            required: true,
            message: "กรุณาเลือกเงื่อนไขสำหรับโครงการ",
            trigger: "change",
          },
        ],
        shipments: [
          {
            required: true,
            message: "กรุณาเลือกวงเงินค้าขายต่อ Shipment",
            trigger: ["blur", "change"],
          },
        ],
        operators: [
          {
            required: true,
            message: "กรุณาเลือกประเภทผู้ประกอบการ",
            trigger: "change",
          },
        ],
        industries: [
          {
            required: true,
            message: "กรุณาเลือกประเภทอุตสาหกรรม",
            trigger: "change",
          },
        ],
        exportValues: [
          {
            required: true,
            message: "กรุณาเลือกมูลค่าการส่งออก",
            trigger: "change",
          },
        ],
        paymentTerms: [
          {
            required: true,
            message: "กรุณาเลือกเทอมการชำระเงิน",
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    this.getProject();
  },
  methods: {
    getProject() {
      HTTP.get(`project/edit/${this.idData}`)
        .then((res) => {
          if (res.data.success) {
            let obj = {
              ...res.data.obj,
              conditionProject: [],
              delImg: "",
              file: [],
              delRate: [],
              delFactor: [],
              delGrade: [],
            };
            this.form = obj;
            this.form.incomeMax =
              this.form.incomeMax == null ? 0 : this.form.incomeMax;
            this.form.incomeMin =
              this.form.incomeMin == null ? 0 : this.form.incomeMin;
            if (this.form.businessSizeStatus == true) {
              this.form.conditionProject.push("businessSizeStatus");
            }
            if (this.form.isGrade == true) {
              this.form.conditionProject.push("gradeStatus");
            }
            if (this.form.operatorStatus == true) {
              this.form.conditionProject.push("operatorTypeStatus");
            }
            if (this.form.industryStatus == true) {
              this.form.conditionProject.push("industryTypeStatus");
            }
            if (this.form.isRateReady == true) {
              this.form.conditionProject.push("readinessStatus");
            }
            if (this.form.isFactorRateReady == true) {
              this.form.conditionProject.push("factorStatus");
            }
            if (this.form.isIncome == true) {
              this.form.conditionProject.push("incomeStatus");
            }
            if (this.form.shipmentStatus == true) {
              this.form.conditionProject.push("shipmentStatus");
            }
            if (this.form.exportValueStatus == true) {
              this.form.conditionProject.push("exportValueStatus");
            }
            if (this.form.paymentTermStatus == true) {
              this.form.conditionProject.push("paymentTermStatus");
            }
            if (this.form.projectTerakStatus == true) {
              this.form.conditionProject.push("projectTERAKStatus");
            }
            if (this.form.conditionProject.length == 11) {
              this.form.conditionProject.unshift(999);
            }
            this.loading = false;
            this.loadingData = false;
            setTimeout(() => {
              window.scroll({ top: 0, left: 0, behavior: "smooth" });
            }, 500);
          } else {
            if (res.data.status == 403) {
              this.$store.commit("SET_PAGE_ACTIVE", null);
              this.$router.push("/not-allowed");
            }
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else if (
            e.response.data.status == 404 ||
            e.response.data.status == 422
          ) {
            this.$message({
              message: "ไม่พบข้อมูลโครงการ",
              type: "error",
              duration: 4000,
            });
            this.$router.push(`/manage/project`);
          } else {
            this.alertCatchError(e);
          }
        });
    },
    async projectEdit(data) {
      this.form = data;
      this.$refs["form"].validate().then(async (valid) => {
        if (valid) {
          this.loadingSave = true;
          let resImg = "";

          if (this.form.file.length > 0) {
            if (typeof this.form.file[0].file != "undefined") {
              var formDataTERAK = new FormData();
              formDataTERAK.append("file", this.form.file[0].file.raw);
              var token = jwt.sign(
                { system: "CFOE2021" },
                "Y2weQEzYjje3LRDiwAUiX1U2r",
                { expiresIn: "5m" }
              );

              TERAK.defaults.headers.common.Authorization = token;
              const res = await TERAK.post(`cfoe/file`, formDataTERAK);
              if (res.data.success) {
                resImg = res.data.filename;
              }
            }
          }
          let forGrade = [];
          let forDelRate = [];
          let forDelFactor = [];
          let forReadinessRate = [];
          let forFactorRate = [];

          let forShipment = [];
          let forExportValue = [];
          let forTerm = [];
          let forProjectTERAK = [];
          let projectTeraks = this.form.projectTeraks.findIndex(
            (a) => a === 999
          );
          if (projectTeraks > -1) {
            this.form.projectTeraks.splice(projectTeraks, 1);
          }

          this.form.projectTeraks.forEach((row) => {
            forProjectTERAK.push(row.toString());
          });
          if (this.form.operateType == 2) {
            let grade = this.form.grade.findIndex((a) => a === 999);
            if (grade > -1) {
              this.form.grade.splice(grade, 1);
            }
            let industries = this.form.industries.findIndex((a) => a === 999);

            if (industries > -1) {
              this.form.industries.splice(industries, 1);
            }

            this.form.factor.forEach((factor) => {
              delete factor.projectId;
            });

            this.form.readiness.forEach((rate) => {
              delete rate.projectId;
            });
            if (this.form.grade.length > 0) {
              this.form.grade.forEach((row) => {
                forGrade.push(row.toString());
              });
            }
            if (this.form.delRate.length > 0) {
              this.form.delRate.forEach((row) => {
                forDelRate.push(row.toString());
              });
            }
            if (this.form.delFactor.length > 0) {
              this.form.delFactor.forEach((row) => {
                forDelFactor.push(row.toString());
              });
            }

            if ((await this.checkSelectType("readinessStatus")) == true) {
              this.form.readiness.forEach((row) => {
                forReadinessRate.push({
                  ...row,
                  id: row.id != null ? row.id.toString() : "",
                });
              });
            }
            if ((await this.checkSelectType("factorStatus")) == true) {
              this.form.factor.forEach((row) => {
                forFactorRate.push({
                  ...row,
                  id: row.id != null ? row.id.toString() : "",
                });
              });
            }

            if (this.form.shipments.length > 0) {
              this.form.shipments.forEach((row) => {
                forShipment.push(row.toString());
              });
            }
            if (this.form.exportValues.length > 0) {
              this.form.exportValues.forEach((row) => {
                forExportValue.push(row.toString());
              });
            }
            if (this.form.paymentTerms.length > 0) {
              this.form.paymentTerms.forEach((row) => {
                forTerm.push(row.toString());
              });
            }
          }
          let obj = {
            operateType: this.form.operateType,
            isRateReady:
              this.form.operateType == 2
                ? await this.checkSelectType("readinessStatus")
                : false,
            isFactorRateReady:
              this.form.operateType == 2
                ? await this.checkSelectType("factorStatus")
                : false,
            isGrade:
              this.form.operateType == 2
                ? await this.checkSelectType("gradeStatus")
                : false,
            isIncome:
              this.form.operateType == 2
                ? await this.checkSelectType("incomeStatus")
                : false,

            shipmentStatus:
              this.form.operateType == 2
                ? await this.checkSelectType("shipmentStatus")
                : false,
            exportValueStatus:
              this.form.operateType == 2
                ? await this.checkSelectType("exportValueStatus")
                : false,
            paymentTermStatus:
              this.form.operateType == 2
                ? await this.checkSelectType("paymentTermStatus")
                : false,
            projectTerakStatus: forProjectTERAK.length > 0 ? true : false,
            industryStatus:
              this.form.operateType == 2
                ? await this.checkSelectType("industryTypeStatus")
                : false,
            operatorStatus:
              this.form.operateType == 2
                ? await this.checkSelectType("operatorTypeStatus")
                : false,
            businessSizeStatus:
              this.form.operateType == 2
                ? await this.checkSelectType("businessSizeStatus")
                : false,
            businessSizeS: this.form.businessSizeS,
            businessSizeM: this.form.businessSizeM,
            businessSizeL: this.form.businessSizeL,
            grade: forGrade,
            operators: this.form.operators,
            industries: this.form.industries,
            readinessRate: forReadinessRate,
            factorRate: forFactorRate,
            incomeMin:
              this.form.incomeMin != "" ? this.form.incomeMin.toString() : "0",
            incomeMax:
              this.form.incomeMax != "" ? this.form.incomeMax.toString() : "0",
            shipments: forShipment,
            exportValues: forExportValue,
            paymentTerms: forTerm,
            projectTeraks: forProjectTERAK,

            // isRateReady: this.form.isRateReady,
            // isFactorRateReady: this.form.isFactorRateReady,
            // isGrade: this.form.isGrade,
            // isIncome: this.form.isIncome,
            // readinessRate:
            //   this.form.isRateReady == true ? forReadinessRate : [],
            // factorRate:
            //   this.form.isFactorRateReady == true ? forFactorRate : [],
            // grade: this.form.isGrade == true ? forGrade : [],
            // incomeMin:
            //   this.form.isIncome == true ? this.form.incomeMin.toString() : "0",
            // incomeMax:
            //   this.form.isIncome == true ? this.form.incomeMax.toString() : "0",
            projectName: this.form.projectName,

            registerStart: this.form.registerStart,
            registerEnd: this.form.registerEnd,
            eventDate: this.form.eventDate,
            eventDateEnd: this.form.eventDateEnd,
            projectDescription:
              this.form.projectDescription != null
                ? this.form.projectDescription
                : "",
            eventLocation: this.form.eventLocation,
            registerType: this.form.registerType.toString(),
            registerUrl: this.form.registerUrl,
            ownerName: this.form.ownerName,
            image: resImg == "" ? this.form.image : resImg,
            delImg:
              this.form.delImg != "" && this.form.delImg != null
                ? this.form.delImg
                : "",
            delRate: forDelRate,
            delFactor: forDelFactor,
          };

          HTTP.put(`project/${this.idData}`, obj)
            .then((res) => {
              if (res.data.success) {
                this.$message({
                  message: "แก้ไขข้อมูลโครงการสำเร็จ",
                  type: "success",
                  duration: 4000,
                });
                this.$router.push(`/manage/project`);
              } else {
                this.loadingSave = false;
                if (res.data.status == 204) {
                  this.$message({
                    message: "ชื่อข้อมูลโครงการซ้ำ",
                    type: "error",
                    duration: 4000,
                  });
                }
              }
            })
            .catch((e) => {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else {
                this.alertCatchError(e);
              }
            });
        }
      });
    },
    formDataOut(data) {
      this.form = data;
    },
    checkSelectType(type) {
      let result = false;
      let find = this.form.conditionProject.find((row) => row == type);
      if (typeof find != "undefined") {
        result = true;
      }
      return result;
    },
  },
};
</script>
