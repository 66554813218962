<template>
  <div>
    <el-card shadow="never" class="card-custom-all">
      <p class="name-head">ลักษณะผู้ประกอบการ</p>
      <el-radio-group
        v-model="formData.operateType"
        class="w-100"
        @change="changeOperateType"
      >
        <el-row :gutter="10">
          <el-col :span="6">
            <el-radio :label="1">บุคคลธรรมดา</el-radio>
          </el-col>
          <el-col :span="6">
            <el-radio :label="2">นิติบุคคล</el-radio>
          </el-col>
        </el-row>
      </el-radio-group>

      <el-row :gutter="30" class="mg-t-3">
        <el-col :span="24">
          <p class="name-head">เงื่อนไขโครงการ</p>
        </el-col>
        <el-col :span="24" :md="12" v-if="formData.operateType == 1">
          <el-form-item label="โครงการจากระบบ TERAK">
            <el-select
              clearable
              filterable
              multiple
              id="formDataProjectTERAK"
              v-model="formData.projectTeraks"
              placeholder="เลือกโครงการจากระบบ TERAK"
              @change="selectTERAK"
              default-first-option
              popper-class="custom-select"
            >
              <el-option
                v-for="item in optionsRef"
                :key="item.id"
                :label="
                  item.expire
                    ? item.projectName + ' (หมดอายุ)'
                    : item.projectName
                "
                :value="item.id"
              >
                <div class="is-flex ai-center">
                  <div
                    class="circle-select"
                    :class="{ 'color-danger': item.expire }"
                  ></div>
                  <div :class="{ 'color-danger': item.expire }">
                    {{ item.projectName }}
                    <span v-if="item.expire" class="mg-l-7"> (หมดอายุ)</span>
                  </div>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="formData.operateType == 2">
          <el-row :gutter="30">
            <el-col :span="24" :md="12">
              <el-form-item
                label="เงื่อนไขสำหรับโครงการ"
                prop="conditionProject"
              >
                <el-select
                  multiple
                  filterable
                  default-first-option
                  clearable
                  v-model="formData.conditionProject"
                  placeholder="เลือกเงื่อนไขสำหรับโครงการ"
                  popper-class="custom-select"
                  class="w-100"
                  @change="selectCondition"
                >
                  <el-option
                    v-for="item in optionsCondition"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                    <div class="is-flex ai-center">
                      <div class="circle-select"></div>
                      {{ item.label }}
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col
              :span="24"
              :md="12"
              class="box-set-tooltip"
              v-if="checkSelectType('businessSizeStatus')"
            >
              <el-form-item label="ขนาดธุรกิจ" class="is-required">
                <el-checkbox v-model="formData.businessSizeS"
                  >Size S</el-checkbox
                >
                <el-checkbox v-model="formData.businessSizeM"
                  >Size M</el-checkbox
                >
                <el-checkbox v-model="formData.businessSizeL"
                  >Size L</el-checkbox
                >
              </el-form-item>
              <el-tooltip
                class="item font-14 custom-tooltip"
                effect="dark"
                placement="bottom-start"
              >
                <div slot="content">
                  <span>
                    ลักษณะธุรกิจ <br />
                    1. ธุรกิจการผลิต คำนวนขนาดธุรกิจจาก สสว. ใหม่ ใช้เกณฑ์
                    <br />
                    - SIZE S 0 - 100 ล้านบาท <br />
                    - SIZE M มากกว่า 100 - 500 ล้านบาท <br />
                    - SIZE L มากกว่า 500 ล้านบาทขึ้นไป <br />
                    2. ธุรกิจบริการ และ ธุรกิจแบบซื้อมาขายไป คำนวนขนาดธุรกิจจาก
                    สสว. ใหม่ ใช้เกณฑ์ <br />
                    - SIZE S 0 - 50 ล้านบาท <br />
                    - SIZE M มากกว่า 50 - 300 ล้านบาท <br />
                    - SIZE L มากกว่า 300 ล้านบาทขึ้นไป
                  </span>
                </div>
                <i class="fas fa-question-circle"></i>
              </el-tooltip>
            </el-col>
            <el-col :span="24" :md="12" v-if="checkSelectType('gradeStatus')">
              <el-form-item label="เกรด" prop="gradeValid" class="is-required">
                <el-select
                  id="selectGrade"
                  multiple
                  filterable
                  default-first-option
                  v-model="formData.grade"
                  placeholder="เลือกเกรด"
                  popper-class="custom-select"
                  @change="selectGrade"
                >
                  <el-option
                    v-for="item in optionsGradeByCredit"
                    :key="item._id"
                    :value="item._id"
                    :label="item.gradeName"
                  >
                    <div class="is-flex ai-center">
                      <div class="circle-select"></div>
                      {{ item.gradeName }}
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :span="24"
              :md="12"
              class="box-set-tooltip"
              v-if="checkSelectType('operatorTypeStatus')"
            >
              <el-form-item label="ประเภทผู้ประกอบการ" prop="operators">
                <el-select
                  clearable
                  multiple
                  filterable
                  id="formDataOperatorType"
                  v-model="formData.operators"
                  placeholder="เลือกประเภทผู้ประกอบการ"
                  default-first-option
                  popper-class="custom-select"
                >
                  <el-option
                    v-for="item in optionsOperatorType"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  >
                    <div class="is-flex ai-center">
                      <div class="circle-select"></div>
                      {{ item.label }}
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-tooltip
                class="item font-14 custom-tooltip operatorType"
                effect="dark"
                placement="bottom-start"
              >
                <div slot="content">
                  <span>
                    ประเภทผู้ประกอบการ มี 3 ตัวเลือก <br />
                    1. ผู้ส่งออก (ประเภทของผู้ประกอบการ: ผู้ส่งออก) <br />
                    2. ผู้มีแผนที่จะส่งออก เลือก <br />
                    - ประเภทของผู้ประกอบการ: ผู้ประกอบการค้าขายภายในประเทศ
                    <br />
                    - มีการผลิต หรือ ขายให้กับผู้ส่งออกโดยตรงหรือไม่: ไม่มีแผน
                    <br />
                    - ภายในปีนี้มีแผนจะเป็นผู้ส่งออกโดยตรงหรือไม่: มีแผน<br />
                    3. ผู้ประกอบการผู้ผลิตเพื่อผู้ส่งออก เลือก<br />
                    - ประเภทของผู้ประกอบการ: ผู้ประกอบการค้าขายภายในประเทศ<br />
                    - มีการผลิต หรือ ขายให้กับผู้ส่งออกโดยตรงหรือไม่: มีแผน<br />
                    - ภายในปีนี้มีแผนจะเป็นผู้ส่งออกโดยตรงหรือไม่: ไม่มีมีแผน<br />
                  </span>
                </div>
                <i class="fas fa-question-circle"></i>
              </el-tooltip>
            </el-col>

            <el-col
              :span="24"
              :md="12"
              v-if="checkSelectType('industryTypeStatus')"
            >
              <el-form-item label="ประเภทอุตสาหกรรม" prop="industries">
                <el-select
                  clearable
                  filterable
                  multiple
                  id="formDataIndustryType"
                  v-model="formData.industries"
                  placeholder="เลือกประเภทอุตสาหกรรม"
                  @change="selectIndusty"
                  default-first-option
                  popper-class="custom-select"
                >
                  <el-option
                    v-for="item in optionsIndusty"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  >
                    <div class="is-flex ai-center">
                      <div class="circle-select"></div>
                      {{ item.label }}
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :span="24"
              class="mg-t-6"
              v-if="checkSelectType('readinessStatus')"
            >
              <el-row
                :gutter="10"
                v-for="(readinessRate, index) in formData.readiness"
                :key="index"
              >
                <el-col :span="24" :md="20">
                  <el-row :gutter="20">
                    <el-col :span="24" :md="12">
                      <el-form-item
                        label="ด้านประเมินความพร้อม"
                        :prop="'readiness.' + index + '.value'"
                        :rules="{
                          required: true,
                          message: 'กรุณาเลือกด้านประเมินความพร้อม',
                          trigger: 'change',
                        }"
                      >
                        <!-- ตอนแรกเลือกเพื่อให้ disabled ปัจจัยจัย -->
                        <!-- <el-select
                      v-model="readinessRate.value"
                      placeholder="เลือกด้านประเมินความพร้อม"
                      @change="filterFactorRate"
                    > -->
                        <el-select
                          :id="`readinessRateValue` + (index + 1)"
                          v-model="readinessRate.value"
                          placeholder="เลือกด้านประเมินความพร้อม"
                        >
                          <el-option
                            v-for="item in optionsCategory"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24" :md="12">
                      <el-form-item class="is-required">
                        <span slot="label">ระดับด้านประเมินความพร้อม</span>
                        <el-row>
                          <el-col :span="24" :sm="11">
                            <el-form-item
                              :prop="'readiness.' + index"
                              :rules="{
                                validator: readinessRateLevelMin,
                                trigger: ['change'],
                              }"
                            >
                              <vue-numeric
                                class="el-input__inner"
                                v-model="readinessRate.levelMin"
                                @keypress.native="isNumber($event)"
                                :precision="1"
                                separator=","
                                placeholder="กรอกตัวเลข"
                                :min="1"
                                :max="4"
                              ></vue-numeric>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24" :sm="2">
                            <p class="mg-0 text-center">ถึง</p>
                          </el-col>
                          <el-col :span="24" :sm="11">
                            <el-form-item
                              :prop="'readiness.' + index"
                              :rules="{
                                validator: readinessRateLevelMax,
                                trigger: ['change'],
                              }"
                            >
                              <vue-numeric
                                class="el-input__inner"
                                v-model="readinessRate.levelMax"
                                @keypress.native="isNumber($event)"
                                :precision="1"
                                separator=","
                                placeholder="กรอกตัวเลข"
                                :min="1"
                                :max="4"
                              ></vue-numeric>
                            </el-form-item>
                          </el-col>
                        </el-row>
                      </el-form-item>
                      <!-- <el-form-item
                    label="ระดับด้านประเมินความพร้อม"
                    :prop="'readiness.' + index + '.level'"
                    :rules="{
                      required: true,
                      message: 'กรุณาเลือกระดับด้านประเมินความพร้อม',
                      trigger: 'change',
                    }"
                  >
                    <el-select
                      :id="`readinessRateLevel` + (index + 1)"
                      v-model="readinessRate.level"
                      placeholder="เลือกระดับ"
                      @change="changeStep"
                    >
                      <el-option
                        v-for="item in optionsReadinessLevel"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item> -->
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="24" :md="4">
                  <el-button
                    :id="`deleteReadinessRate` + (index + 1)"
                    type="danger"
                    class="btn-del"
                    :disabled="formData.readiness.length > 1 ? false : true"
                    @click="delReadiness(index)"
                    >ลบ</el-button
                  >
                </el-col>
              </el-row>
              <p class="font-20 color-main text-center mg-t-6 cursor-pointer">
                <span id="addReadiness" @click="AddReadiness">
                  + เพิ่มข้อมูลด้านประเมินความพร้อม
                </span>
              </p>
            </el-col>
            <el-col :span="24" v-if="checkSelectType('factorStatus')">
              <el-row
                :gutter="10"
                v-for="(factorRate, index) in formData.factor"
                :key="+index"
              >
                <el-col :span="24" :md="20">
                  <el-row :gutter="20">
                    <el-col :span="24" :md="12">
                      <el-form-item
                        label="ปัจจัยประเมินความพร้อม"
                        :prop="'factor.' + index + '.value'"
                        :rules="{
                          required: true,
                          message: 'กรุณาเลือกปัจจัยประเมินความพร้อม',
                          trigger: 'change',
                        }"
                      >
                        <!-- พี่ฟลุ๊คบอกว่าให้ปล่อยไปเลยไม่ต้อง disabled -->
                        <!-- <el-select
                      v-model="factorRate.value"
                      placeholder="เลือกด้านประเมินความพร้อม"
                      @change="selectFactorRate"
                    > -->
                        <el-select
                          :id="`factorRateValue` + (index + 1)"
                          v-model="factorRate.value"
                          placeholder="เลือกด้านประเมินความพร้อม"
                        >
                          <el-option
                            v-for="item in optionsQuestion"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                            :disabled="item.disabled"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24" :md="12">
                      <el-form-item
                        label="ระดับปัจจัยประเมินความพร้อม"
                        :prop="'factor.' + index + '.score'"
                        :rules="{
                          required: true,
                          message: 'กรุณาเลือกระดับปัจจัยประเมินความพร้อม',
                          trigger: 'change',
                        }"
                      >
                        <el-select
                          :id="`factorRateLevel` + (index + 1)"
                          v-model="factorRate.score"
                          placeholder="เลือกระดับ"
                          @change="changeStep"
                          multiple
                          popper-class="custom-select"
                        >
                          <el-option
                            v-for="item in optionsFactorRateLevel"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                            <div class="is-flex ai-center">
                              <div class="circle-select"></div>
                              {{ item.label }}
                            </div>
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="24" :md="4">
                  <el-button
                    :id="`deleteFactorRate` + (index + 1)"
                    type="danger"
                    class="btn-del"
                    :disabled="formData.factor.length > 1 ? false : true"
                    @click="delFactors(index)"
                    >ลบ</el-button
                  >
                </el-col>
              </el-row>
              <p class="font-20 color-main text-center mg-t-6 cursor-pointer">
                <span id="addFactors" @click="AddFactors">
                  + เพิ่มข้อมูลปัจจัยประเมินความพร้อม
                </span>
              </p>
            </el-col>
            <el-col :span="24" :md="12" v-if="checkSelectType('incomeStatus')">
              <el-form-item class="is-required mg-0">
                <span slot="label">รายได้จากการดำเนินงาน (บาท)</span>
                <el-row>
                  <el-col :span="24" :sm="11">
                    <el-form-item prop="incomeMinValid">
                      <vue-numeric
                        id="incomeMin"
                        class="el-input__inner"
                        v-model="formData.incomeMin"
                        @keypress.native="isNumber($event)"
                        placeholder="กรอกตัวเลขรายได้"
                        :max="9999999999999"
                      ></vue-numeric>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="2">
                    <p class="mg-0 text-center">ถึง</p>
                  </el-col>
                  <el-col :span="24" :sm="11">
                    <el-form-item prop="incomeMaxValid">
                      <vue-numeric
                        id="incomeMax"
                        class="el-input__inner"
                        v-model="formData.incomeMax"
                        @keypress.native="isNumber($event)"
                        placeholder="กรอกตัวเลขรายได้"
                        :max="9999999999999"
                      ></vue-numeric>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col
              :span="24"
              :md="12"
              v-if="checkSelectType('shipmentStatus')"
            >
              <el-form-item label="วงเงินค้าขายต่อ Shipment" prop="shipments">
                <el-select
                  clearable
                  id="formDataShipment"
                  v-model="formData.shipments"
                  placeholder="เลือกวงเงินค้าขายต่อ Shipment"
                  multiple
                  default-first-option
                  popper-class="custom-select"
                >
                  <el-option
                    v-for="item in optionsShipment"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                    <div class="is-flex ai-center">
                      <div class="circle-select"></div>
                      {{ item.label }}
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :span="24"
              :md="12"
              v-if="checkSelectType('exportValueStatus')"
            >
              <el-form-item label="มูลค่าการส่งออก" prop="exportValues">
                <el-select
                  clearable
                  id="formDataExportValue"
                  v-model="formData.exportValues"
                  placeholder="มูลค่าการส่งออก"
                  multiple
                  default-first-option
                  popper-class="custom-select"
                >
                  <el-option
                    v-for="item in optionsExportValue"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                    <div class="is-flex ai-center">
                      <div class="circle-select"></div>
                      {{ item.label }}
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :span="24"
              :md="12"
              v-if="checkSelectType('paymentTermStatus')"
            >
              <el-form-item label="เทอมการชำระเงิน" prop="paymentTerms">
                <el-select
                  clearable
                  id="formDataPaymentTerm"
                  v-model="formData.paymentTerms"
                  placeholder="เทอมการชำระเงิน"
                  multiple
                  default-first-option
                  popper-class="custom-select"
                >
                  <el-option
                    v-for="item in optionsPaymentTerm"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                    <div class="is-flex ai-center">
                      <div class="circle-select"></div>
                      {{ item.label }}
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :span="24"
              :md="12"
              v-if="checkSelectType('projectTERAKStatus')"
            >
              <el-form-item label="โครงการจากระบบ TERAK" prop="projectTeraks">
                <el-select
                  clearable
                  filterable
                  multiple
                  id="formDataProjectTERAK"
                  v-model="formData.projectTeraks"
                  placeholder="เลือกโครงการจากระบบ TERAK"
                  @change="selectTERAK"
                  default-first-option
                  popper-class="custom-select"
                >
                  <el-option
                    v-for="item in optionsRef"
                    :key="item.id"
                    :label="
                      item.expire
                        ? item.projectName + ' (หมดอายุ)'
                        : item.projectName
                    "
                    :value="item.id"
                  >
                    <div class="is-flex ai-center">
                      <div
                        class="circle-select"
                        :class="{ 'color-danger': item.expire }"
                      ></div>
                      <div :class="{ 'color-danger': item.expire }">
                        {{ item.projectName }}
                        <span v-if="item.expire" class="mg-l-7">
                          (หมดอายุ)</span
                        >
                      </div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24">
          <el-row :gutter="20">
            <el-col :span="24" :md="10">
              <el-form-item label="สมัครโครงการ" prop="registerType">
                <el-radio-group v-model="formData.registerType" class="w-100">
                  <el-row>
                    <el-col :span="12">
                      <el-radio :label="1">โครงการของหน่วยงาน EXAC</el-radio>
                    </el-col>
                    <el-col :span="12">
                      <el-radio :label="2">โครงการของหน่วยงานพันธมิตร</el-radio>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col
              :span="24"
              :md="14"
              v-if="formData.registerType == 1 || formData.registerType == 2"
            >
              <el-form-item label="แนบลิงก์สมัครโครงการ" prop="registerUrl">
                <el-input
                  placeholder="แนบลิงก์สมัครโครงการ"
                  v-model="formData.registerUrl"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col :span="24">
          <el-row :gutter="10">
            <el-col :span="12" :sm="12" :md="6">
              <el-checkbox
                v-model="formData.isRateReady"
                @change="changeStep('box1')"
                >ด้านประเมินความพร้อม</el-checkbox
              >
            </el-col>
            <el-col :span="12" :sm="12" :md="6">
              <el-checkbox
                v-model="formData.isFactorRateReady"
                @change="changeStep('box1')"
                >ปัจจัยประเมินความพร้อม</el-checkbox
              >
            </el-col>
            <el-col :span="12" :sm="12" :md="6">
              <el-checkbox
                v-model="formData.isGrade"
                @change="changeStep('box1')"
                >เกรดตามเกณฑ์สินเชื่อ</el-checkbox
              >
            </el-col>
            <el-col :span="12" :sm="12" :md="6">
              <el-checkbox
                v-model="formData.isIncome"
                @change="changeStep('box1')"
                >รายได้จากการดำเนินงาน (บาท)</el-checkbox
              >
            </el-col>
          </el-row>
        </el-col> -->
        <!-- <el-col :span="24" v-if="formData.isFactorRateReady">
          <el-row
            :gutter="10"
            v-for="(factorRate, index) in formData.factor"
            :key="+index"
          >
            <el-col :span="24" :md="20">
              <el-row :gutter="20">
                <el-col :span="24" :md="12">
                  <el-form-item
                    label="ปัจจัยประเมินความพร้อม"
                    :prop="'factor.' + index + '.value'"
                    :rules="{
                      required: true,
                      message: 'กรุณาเลือกปัจจัยประเมินความพร้อม',
                      trigger: 'change',
                    }"
                  >
                    พี่ฟลุ๊คบอกว่าให้ปล่อยไปเลยไม่ต้อง disabled
                    <el-select
                      v-model="factorRate.value"
                      placeholder="เลือกด้านประเมินความพร้อม"
                      @change="selectFactorRate"
                    >
                    <el-select
                      :id="`factorRateValue` + (index + 1)"
                      v-model="factorRate.value"
                      placeholder="เลือกด้านประเมินความพร้อม"
                    >
                      <el-option
                        v-for="item in optionsQuestion"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                        :disabled="item.disabled"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="24" :md="12">
                  <el-form-item
                    label="ระดับปัจจัยประเมินความพร้อม"
                    :prop="'factor.' + index + '.score'"
                    :rules="{
                      required: true,
                      message: 'กรุณาเลือกระดับปัจจัยประเมินความพร้อม',
                      trigger: 'change',
                    }"
                  >
                    <el-select
                      :id="`factorRateLevel` + (index + 1)"
                      v-model="factorRate.score"
                      placeholder="เลือกระดับ"
                      @change="changeStep"
                      multiple
                      popper-class="custom-select"
                    >
                      <el-option
                        v-for="item in optionsFactorRateLevel"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                        <div class="is-flex ai-center">
                          <div class="circle-select"></div>
                          {{ item.label }}
                        </div>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="24" :md="4">
              <el-button
                :id="`deleteFactorRate` + (index + 1)"
                type="danger"
                class="btn-del"
                :disabled="formData.factor.length > 1 ? false : true"
                @click="delFactors(index)"
                >ลบ</el-button
              >
            </el-col>
          </el-row>
          <p class="font-20 color-main text-center mg-t-6 cursor-pointer">
            <span id="addFactors" @click="AddFactors">
              + เพิ่มข้อมูลปัจจัยประเมินความพร้อม
            </span>
          </p>
        </el-col> -->
        <!-- <el-col :span="24">
          <el-row :gutter="20">
            <el-col :span="24" :md="10" v-if="formData.isGrade">
              <el-form-item
                label="เกรดตามเกณฑ์สินเชื่อ"
                prop="gradeValid"
                class="is-required"
              >
                <el-select
                  id="selectGrade"
                  multiple
                  filterable
                  default-first-option
                  v-model="formData.grade"
                  placeholder="เลือกเกรด"
                  popper-class="custom-select"
                  @change="selectGrade"
                >
                  <el-option
                    v-for="item in optionsGradeByCredit"
                    :key="item._id"
                    :value="item._id"
                    :label="item.gradeName"
                  >
                    <div class="is-flex ai-center">
                      <div class="circle-select"></div>
                      {{ item.gradeName }}
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="14" v-if="formData.isIncome">
              <el-form-item class="is-required">
                <span slot="label">รายได้จากการดำเนินงาน (บาท)</span>
                <el-row>
                  <el-col :span="24" :sm="11">
                    <el-form-item prop="incomeMinValid">
                      <vue-numeric
                        id="incomeMin"
                        class="el-input__inner"
                        v-model="formData.incomeMin"
                        @keypress.native="isNumber($event)"
                        placeholder="กรอกตัวเลขรายได้"
                        :max="9999999999999"
                      ></vue-numeric>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="2">
                    <p class="mg-0 text-center">ถึง</p>
                  </el-col>
                  <el-col :span="24" :sm="11">
                    <el-form-item prop="incomeMaxValid">
                      <vue-numeric
                        id="incomeMax"
                        class="el-input__inner"
                        v-model="formData.incomeMax"
                        @keypress.native="isNumber($event)"
                        placeholder="กรอกตัวเลขรายได้"
                        :max="9999999999999"
                      ></vue-numeric>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col> -->
        <!-- <el-col
          :span="24"
          v-if="
            formData.isRateReady ||
            formData.isFactorRateReady ||
            formData.isGrade ||
            formData.isIncome
          "
        >
          <el-row :gutter="20">
            <el-col :span="24" :md="10">
              <el-form-item label="สมัครโครงการ" prop="registerType">
                <el-radio-group v-model="formData.registerType" class="w-100">
                  <el-row>
                    <el-col :span="12">
                      <el-radio :label="1">โครงการของหน่วยงาน EXAC</el-radio>
                    </el-col>
                    <el-col :span="12">
                      <el-radio :label="2">โครงการของหน่วยงานพันธมิตร</el-radio>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="14" v-if="formData.registerType == 2">
              <el-form-item label="แนบลิงก์สมัครโครงการ" prop="registerUrl">
                <el-input
                  placeholder="แนบลิงก์สมัครโครงการ"
                  v-model="formData.registerUrl"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col> -->
      </el-row>
    </el-card>
  </div>
</template>
<script>
import { TERAK } from "@/service/axios";
import { HTTP } from "@/service/axios";
import moment from "moment";
import crypto from "crypto";
const algorithm = "aes-256-ctr";
const secretKey = "vwPH9sdmpnVjrTIQCc7qSXs11lWhzFrT";
const iv = crypto.randomBytes(16);
export default {
  props: ["formData"],
  watch: {
    formData() {
      this.formData.grade = this.formData.grade.map((e) => e.gradeId);
      if (this.formData.grade.length == this.optionsGradeByCredit.length - 1) {
        this.formData.grade.unshift(999);
      }
      this.constSelect = JSON.parse(JSON.stringify(this.formData.grade));
    },
  },
  data() {
    var validReadinessRateLevel = (rule, value, callback) => {
      if (value.levelMin === "") {
        callback(new Error("กรุณากรอกตัวเลข"));
      } else {
        if (value.levelMax < value.levelMin) {
          callback(new Error("ตัวเลขสิ้นสุดน้อยกว่าตัวเลขเริ่มต้น"));
        } else if (value.incomeMax > 90000000000) {
          callback(new Error("กรุณากรอกตัวเลขไม่เกิน 9 หมื่นล้าน"));
        } else {
          callback();
        }
      }
    };
    var validReadinessRateLevelMax1 = (rule, value, callback) => {
      if (value.levelMax === "") {
        callback(new Error("กรุณากรอกตัวเลข"));
      } else {
        if (value.levelMin > value.levelMax) {
          callback(new Error("ตัวเลขเริ่มต้นมากกว่าตัวเลขสิ้นสุด"));
        } else if (value.levelMax > 90000000000) {
          callback(new Error("กรุณากรอกตัวเลขไม่เกิน 9 หมื่นล้าน"));
        } else {
          callback();
        }
      }
    };
    return {
      constSelect: [],
      readinessRateLevelMax: validReadinessRateLevelMax1,
      readinessRateLevelMin: validReadinessRateLevel,

      optionsGradeByCredit: [],
      optionsCategory: [],
      optionsQuestion: [],
      optionsReadinessLevel: this.$store.state.userProject.readinessLevel,
      optionsFactorRateLevel: [
        {
          value: 1,
          label: "ระดับ 1",
        },
        {
          value: 2,
          label: "ระดับ 2",
        },
        {
          value: 3,
          label: "ระดับ 3",
        },
        {
          value: 4,
          label: "ระดับ 4",
        },
      ],
      optionsQuestionNew: [],

      constSelect1: [],
      constSelect2: [],
      constSelect3: [],
      optionsRef: [],
      optionsOperatorType: [
        {
          id: 1,
          label: "ผู้ส่งออก",
        },
        {
          id: 2,
          label: "ผู้มีแผนที่จะส่งออก",
        },
        {
          id: 3,
          label: "ผู้ประกอบการผู้ผลิตเพื่อผู้ส่งออก",
        },
      ],

      optionsCondition: [
        {
          value: Number("999"),
          label: "ทั้งหมด",
        },
        {
          value: "businessSizeStatus",
          label: "ขนาดธุรกิจ",
        },
        {
          value: "gradeStatus",
          label: "เกรด",
        },
        {
          value: "operatorTypeStatus",
          label: "ประเภทผู้ประกอบการ",
        },
        {
          value: "industryTypeStatus",
          label: "ประเภทอุตสาหกรรม",
        },
        {
          value: "readinessStatus",
          label: "ด้านประเมินความพร้อม",
        },
        {
          value: "factorStatus",
          label: "ปัจจัยประเมินความพร้อม",
        },
        {
          value: "incomeStatus",
          label: "รายได้จากการดำเนินงาน (บาท)",
        },
        {
          value: "shipmentStatus",
          label: "วงเงินค้าขายต่อ Shipment",
        },
        {
          value: "exportValueStatus",
          label: "มูลค่าการส่งออก",
        },
        {
          value: "paymentTermStatus",
          label: "เทอมการชำระเงิน",
        },
        {
          value: "projectTERAKStatus",
          label: "โครงการจากระบบ TERAK",
        },
      ],
      operateType: null,
      optionsIndusty: [],
      optionsExportValue: [
        { value: 1, label: "ไม่เกิน 100 ล้านบาท" },
        { value: 2, label: "101-200 ล้านบาท" },
        { value: 3, label: "201-300 ล้านบาท" },
        { value: 4, label: "301-400 ล้านบาท" },
        { value: 5, label: "401-500 ล้านบาท" },
        { value: 6, label: "มากกว่า 500 ล้านบาท" },
      ],
      optionsShipment: [
        {
          value: 1,
          label: "น้อยกว่า 0.5 ล้านบาท",
        },
        {
          value: 2,
          label: "0.5 - 1 ล้านบาท",
        },
        {
          value: 3,
          label: "1 ล้านบาทขึ้นไป",
        },
      ],
      optionsPaymentTerm: [
        {
          value: 90,
          label: "ไม่เกิน 90 วัน",
        },
        {
          value: 120,
          label: "ไม่เกิน 120 วัน",
        },
        {
          value: 180,
          label: "ไม่เกิน 180 วัน",
        },
      ],
    };
  },
  computed: {
    _token() {
      return this.$store.state._token;
    },
    optionsIndustryType() {
      return this.$store.state.industryType;
    },
  },
  async mounted() {
    this.formData.grade = await this.formData.grade.map((e) => e.gradeId);
    await this.getGrade();

    this.getProjectByTerak("CFOE2021");
    // this.getByTokenTerak("CFOE2021");
    this.getTerak();
    this.checkAll();
  },
  methods: {
    
    changeOperateType(){
      this.formData.conditionProject = []
      this.formData.projectTeraks = []
      this.changeStep()
    },
    async changeStep(val) {
      if (val == "box1") {
        // if (this.formData.isRateReady) {

        if ((await this.checkSelectType("readinessStatus")) == true) {
          if (this.formData.readiness.length == 0) {
            this.AddReadiness();
          }
          // this.AddFactors();
          // this.formData.isFactorRateReady = true;
        }
        // } else {
        //   this.formData.readiness = [];
        // this.formData.factorRate = [];
        // this.formData.isFactorRateReady = false;
        // }

        // if (this.formData.isFactorRateReady) {
        if ((await this.checkSelectType("factorStatus")) == true) {
          if (this.formData.factor.length == 0) {
            this.AddFactors();
          }
        }
        // } else {
        //   this.formData.factor = [];
        // }
        // if (this.formData.isRateReady) {
        //   if (this.formData.readiness.length == 0) {
        //     this.AddReadiness();
        //     this.AddFactors();
        //     this.formData.isFactorRateReady = true;
        //   }
        //   // else {
        //   //   this.formData.readiness = [];
        //   //   this.formData.factor = [];
        //   // }
        // } else {
        //   this.formData.isFactorRateReady = false;
        // }
      }
      this.$emit("stepNow", this.formData);
    },
    AddReadiness() {
      this.formData.readiness.push({
        id: null,
        value: "",
        levelMin: "",
        levelMax: "",
      });
    },
    delReadiness(index) {
      if (typeof this.formData.readiness[index].id != "undefined") {
        this.formData.delRate.push(this.formData.readiness[index].id);
      }
      this.formData.readiness.splice(index, 1);
      // this.filterFactorRate();
    },
    AddFactors() {
      this.formData.factor.push({ id: null, value: "", score: [] });
    },
    delFactors(index) {
      if (typeof this.formData.factor[index].id != "undefined") {
        this.formData.delFactor.push(this.formData.factor[index].id);
      }
      this.formData.factor.splice(index, 1);
      // this.selectFactorRate();
    },
    getTerak() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`terak`)
        .then((res) => {
          if (res.data.success) {
            this.optionsCategory = res.data.obj.cates;
            this.optionsQuestion = res.data.obj.questions;
            this.loading = true;
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
        });
    },
    getByTokenTerak(text) {
      try {
        var ivstring = iv.toString("hex").slice(0, 16);
        const cipher = crypto.createCipheriv(algorithm, secretKey, ivstring);
        const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);

        let obj = {
          iv: ivstring.toString("hex"),
          content: encrypted.toString("hex"),
        };
        TERAK.defaults.headers.common.Authorization = `Bearer ${
          obj.iv + ":" + obj.content
        }`;
        TERAK.get(`cfoe/cates/questions`)
          .then((res) => {
            if (res.data.success) {
              this.optionsCategory = res.data.cates;
              this.optionsQuestion = res.data.questions;
              this.loading = true;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } catch (e) {
        console.log("Error: encrypt", e);
        return false;
      }
    },
    getGrade() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`criterion/grade/all`)
        .then(async (res) => {
          if (res.data.success) {
            this.optionsGradeByCredit = res.data.obj;
            this.optionsGradeByCredit.unshift({
              _id: Number("999"),
              gradeName: "ทั้งหมด",
            });
            if (
              this.formData.grade.length ==
              this.optionsGradeByCredit.length - 1
            ) {
              this.formData.grade.unshift(999);
            }
            this.constSelect = await JSON.parse(
              JSON.stringify(this.formData.grade)
            );

            // consoloe.log(this.formData)
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
        });
    },
    setGrade() {
      this.formData.grade = this.formData.grade.map((e) => e.gradeId);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode != 189
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    selectGrade(val) {
      if (val[val.length - 1] == 999) {
        this.formData.grade = [];
        this.optionsGradeByCredit.forEach((gAll) => {
          this.formData.grade.push(gAll._id);
        });
        this.constSelect = JSON.parse(JSON.stringify(this.formData.grade));
      } else {
        if (val[0] === 999 && val.length < this.optionsGradeByCredit.length) {
          this.formData.grade.splice(0, 1);
          this.constSelect = JSON.parse(JSON.stringify(this.formData.grade));
        } else if (this.constSelect[0] === 999 && val[0] !== 999) {
          this.formData.grade = [];
          this.constSelect = JSON.parse(JSON.stringify(this.formData.grade));
        } else {
          let fn = this.constSelect.findIndex((a) => a === 999);
          if (val.length == this.optionsGradeByCredit.length - 1 && fn === -1) {
            this.formData.grade = [];
            this.optionsGradeByCredit.forEach((gAll) => {
              this.formData.grade.push(gAll._id);
            });
            this.constSelect = JSON.parse(JSON.stringify(this.formData.grade));
          }
        }
      }
    },
    getProjectByTerak(text) {
      try {
        var ivstring = iv.toString("hex").slice(0, 16);
        const cipher = crypto.createCipheriv(algorithm, secretKey, ivstring);
        const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);

        let obj = {
          iv: ivstring.toString("hex"),
          content: encrypted.toString("hex"),
        };
        TERAK.defaults.headers.common.Authorization = `Bearer ${
          obj.iv + ":" + obj.content
        }`;
        this.optionsRef = [];
        TERAK.get(`cfoe/refs`)
          .then((res) => {
            if (res.data.success) {
              res.data.obj.forEach((item) => {
                let expire = false;
                // let dateNow = moment(new Date()).format("yyyy-MM-DD");
                // let dateEnd = moment(item.endDate).format("yyyy-MM-DD");
                // if (dateNow > dateEnd) {
                //   expire = true;
                // }
                !expire
                  ? this.optionsRef.push({
                      ...item,
                      expire: expire,
                    })
                  : "";
              });
              this.optionsRef.unshift({
                id: Number("999"),
                expire: false,
                projectName: "ทั้งหมด",
              });
              if (
                this.formData.projectTeraks.length ==
                this.optionsRef.length - 1
              ) {
                this.formData.projectTeraks.unshift(999);
              }
              this.constSelect2 = JSON.parse(
                JSON.stringify(this.formData.projectTeraks)
              );
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } catch (e) {
        console.log("Error: encrypt", e);
        return false;
      }
    },
    selectTERAK(val) {
      if (val[val.length - 1] == 999) {
        this.formData.projectTeraks = [];
        this.optionsRef.forEach((gAll) => {
          if (gAll.expire == false) {
            this.formData.projectTeraks.push(gAll.id);
          }
        });
        this.constSelect2 = JSON.parse(
          JSON.stringify(this.formData.projectTeraks)
        );
      } else {
        if (val[0] === 999 && val.length < this.optionsRef.length) {
          this.formData.projectTeraks.splice(0, 1);
          this.constSelect2 = JSON.parse(
            JSON.stringify(this.formData.projectTeraks)
          );
        } else if (this.constSelect2[0] === 999 && val[0] !== 999) {
          this.formData.projectTeraks = [];
          this.constSelect2 = JSON.parse(
            JSON.stringify(this.formData.projectTeraks)
          );
        } else {
          let fn = this.constSelect2.findIndex((a) => a === 999);
          if (val.length == this.optionsRef.length - 1 && fn === -1) {
            this.formData.projectTeraks = [];
            this.optionsRef.forEach((gAll) => {
              gAll.expire == false
                ? this.formData.projectTeraks.push(gAll.id)
                : "";
            });
            this.constSelect2 = JSON.parse(
              JSON.stringify(this.formData.projectTeraks)
            );
          }
        }
      }
      this.changeStep();
    },
    // filterFactorRate() {
    //   let checkArrRate = [];
    //   this.optionsQuestionNew = [];
    //   for (let i = 0; i < this.formData.readiness.length; i++) {
    //     checkArrRate.push(this.formData.readiness[i].value);
    //   }
    //   checkArrRate.map((e) => {
    //     let setArr = this.optionsQuestion.filter((b) => b.cateId == e);
    //     setArr.map((f) => {
    //       this.optionsQuestionNew.push({ ...f, disabled: false });
    //     });
    //   });
    //   this.changeStep();
    // },
    // selectFactorRate() {
    //   let selectNow = [];
    //   for (let i = 0; i < this.formData.factor.length; i++) {
    //     selectNow.push(this.formData.factor[i].value);
    //   }
    //   const opt = this.optionsQuestionNew.map((row) => {
    //     row.disabled = false;
    //     selectNow.forEach((id) => {
    //       if (id === row.id) {
    //         row.disabled = true;
    //       }
    //     });
    //     return row;
    //   });
    //   this.optionsQuestionNew = opt;
    // },
    checkAll() {
      this.optionsIndusty = JSON.parse(
        JSON.stringify(this.optionsIndustryType)
      );
      let industry = this.optionsIndusty.some((e) => e.id == "999");
      if (!industry) {
        this.optionsIndusty.unshift({
          id: Number("999"),
          label: "ทั้งหมด",
        });
      }
      if (this.formData.industries.length == this.optionsIndusty.length - 1) {
        this.formData.industries.unshift(999);
      }
      this.constSelect1 = JSON.parse(JSON.stringify(this.formData.industries));
    },
    selectIndusty(val) {
      if (val[val.length - 1] == 999) {
        this.formData.industries = [];
        this.optionsIndusty.forEach((gAll) => {
          this.formData.industries.push(gAll.id);
        });
        this.constSelect1 = JSON.parse(
          JSON.stringify(this.formData.industries)
        );
      } else {
        if (val[0] === 999 && val.length < this.optionsIndusty.length) {
          this.formData.industries.splice(0, 1);
          this.constSelect1 = JSON.parse(
            JSON.stringify(this.formData.industries)
          );
        } else if (this.constSelect1[0] === 999 && val[0] !== 999) {
          this.formData.industries = [];
          this.constSelect1 = JSON.parse(
            JSON.stringify(this.formData.industries)
          );
        } else {
          let fn = this.constSelect1.findIndex((a) => a === 999);
          if (val.length == this.optionsIndusty.length - 1 && fn === -1) {
            this.formData.industries = [];
            this.optionsIndusty.forEach((gAll) => {
              this.formData.industries.push(gAll.id);
            });
            this.constSelect1 = JSON.parse(
              JSON.stringify(this.formData.industries)
            );
          }
        }
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode != 189
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    checkSelectType(type) {
      let result = false;
      let find = this.formData.conditionProject.find((row) => row == type);
      if (typeof find != "undefined") {
        result = true;
      } else {
        if (type == "businessSizeStatus") {
          this.formData.businessSizeS = false;
          this.formData.businessSizeM = false;
          this.formData.businessSizeL = false;
        } else if (type == "gradeStatus") {
          this.formData.grade = [];
        } else if (type == "operatorTypeStatus") {
          this.formData.operators = [];
        } else if (type == "industryTypeStatus") {
          this.formData.industries = [];
        } else if (type == "readinessStatus") {
          this.formData.readinessRate = [];
        } else if (type == "factorStatus") {
          this.formData.factorRate = [];
        } else if (type == "incomeStatus") {
          this.formData.incomeMin = "";
          this.formData.incomeMax = "";
        } else if (type == "shipmentStatus") {
          this.formData.shipments = [];
        } else if (type == "exportValueStatus") {
          this.formData.exportValues = [];
        } else if (type == "paymentTermStatus") {
          this.formData.paymentTerms = [];
        } else if (type == "projectTERAKStatus") {
          this.formData.projectTeraks = [];
        }
      }
      return result;
    },
    selectCondition(val) {
      if (val[val.length - 1] == 999) {
        this.formData.conditionProject = [];
        this.optionsCondition.forEach((gAll) => {
          this.formData.conditionProject.push(gAll.value);
        });
        this.constSelect3 = JSON.parse(
          JSON.stringify(this.formData.conditionProject)
        );
      } else {
        if (val[0] === 999 && val.length < this.optionsCondition.length) {
          this.formData.conditionProject.splice(0, 1);
          this.constSelect3 = JSON.parse(
            JSON.stringify(this.formData.conditionProject)
          );
        } else if (this.constSelect3[0] === 999 && val[0] !== 999) {
          this.formData.conditionProject = [];
          this.constSelect3 = JSON.parse(
            JSON.stringify(this.formData.conditionProject)
          );
        } else {
          let fn = this.constSelect3.findIndex((a) => a === 999);
          if (val.length == this.optionsCondition.length - 1 && fn === -1) {
            this.formData.conditionProject = [];
            this.optionsCondition.forEach((gAll) => {
              this.formData.conditionProject.push(gAll.value);
            });
            this.constSelect3 = JSON.parse(
              JSON.stringify(this.formData.conditionProject)
            );
          }
        }
      }
      this.changeStep("box1");
    },
  },
};
</script>